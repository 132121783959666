/* eslint-disable vue/multi-word-component-names */
<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column">
        <h1>About Page</h1>
        <h2>
          City of Philadelphia&rsquo;s Eviction Diversion Program
        </h2>
        <p class="faq-line-indent">
          The City of Philadelphia's FREE Eviction Diversion Program (EDP) helps landlords and tenants resolve
          disputes and avoid going to court. EDP facilitates satisfactory and sustainable landlord-tenant agreements
          through mediation or supported direct negotiation. Per City Ordinance <a href="https://phila.legistar.com/LegislationDetail.aspx?ID=5843377&GUID=2C1F68C2-2053-4B0D-B7D6-D4953F2C77AA&Options=ID%7CText%7C&Search=220655"> <u># 220655</u></a>, landlord good faith
          participation in EDP is required before seeking a legal eviction through court. Landlords start the
          eviction diversion process by sending tenants the <a href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/NOTICE+OF+DIVERSION+RIGHTS+FINAL+3.10.2022.pdf"><u>2022 Notice of Diversion Rights (NOR)</u></a>.
          Eligible landlords and tenants in the Eviction Diversion Program may also be selected to apply for Targeted
          Financial Assistance, a one-time payment from the City to a landlord to cover a tenant’s rent arrears.
          <br><br>The benefits of participation in the Eviction Diversion Program are:
          <br><ul>
            <li>Tenants avoid an eviction filing or judgment on their record that could prevent them from accessing quality housing in the future.  </li>
            <li>Landlords save money by avoiding court fees and attorney expenses.</li>
            <li>The court reduces caseloads, putting less strain on the court system.</li>
            <li>Low-income renters and landlords get help applying for rental assistance and other resources to stabilize their housing during the pandemic.</li>
            <li>Landlords recover back rent owed while avoiding vacancies and unit turnover costs</li>
          </ul>
        </p><br><br>
        <b>Definitions</b><br>
        <p class="faq-line-indent">
          <u>Eviction Diversion Program (EDP)</u> provides tenants and landlords an opportunity to resolve residential
          rental issues while avoiding a costly or drawn-out court process. EDP applications are assigned to a
          pathway which may include mediation, direct negotiation, and/or financial assistance depending on a
          variety of factors, including the amount of back rent owed. Not every EDP case will be assigned to
          mediation

          <br><br><u>Mediation,</u> if assigned, is a free opportunity for tenants and landlords to meet over the telephone
          to seek a resolution before going to court.  Participants work together with the help of a neutral mediator.
          Landlords and tenants are required to give this dialogue a good faith effort before seeking to evict in court.
          If an agreement is reached during a mediation session, the mediator will draft an agreement and the program will
          send it to participants within three (3) business days. Landlords are required to seek resolution for at least
          thirty days from the date of application approval. Following thirty days, or following the mediation process, an
          outcome will be provided to the landlord and tenant concluding the Eviction Diversion Program.

          <br><br><u>Direct negotiation resources</u> may be provided instead of mediation. Tenants and landlords are encouraged to
          try to work out agreements using the resources and templates provided below. Resources include a step-by-step
          resource manual and agreement templates. Landlords must communicate with tenants to meet the good faith requirement
          of participation. If an agreement is reached through direct negotiation, a copy must be emailed to
          <a href="mailto:EvictionDiversionProgram@coraservices.org">EvictionDiversionProgram@coraservices.org</a>.

          <br><br><u>Targeted Financial Assistance (TFA)</u> may be awarded if a landlord and tenant meet program eligibility, 
          including the absence of a Landlord Tenant complaint on record for the same landlord, tenant, and unit. 
          Landlords must also participate in the program in good faith. The award is equal to the total amount of arrears plus two months’ rent. 
          Eligibility is determined after an Eviction Diversion Program application is submitted.

          <br><br><u>Good Faith Participation</u> means that a tenant and landlord have both made reasonable efforts to negotiate a resolution.

          <br><br>For a landlord to meet the definition of good faith participation, the following conditions must be met:
          <br><ul>
            <li>
              Provide evidence that the <a href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/NOTICE+OF+DIVERSION+RIGHTS+FINAL+3.10.2022.pdf">
                <u>2022 Notice of Diversion Rights</u></a> (most recent version) was sent to tenant, updated ledger account of any balances owed, and any additional
              issues to address through mediation as required.
            </li>
            <li>Provide current and accurate contact information for tenant(s) as part of the Eviction Diversion Program application.</li>
            <li>
              Respond timely to requests for communication from a tenant, a housing counselor, or City-funded agency representative
              to resolve application issues or reach an agreement.
            </li>
            <li>In direct negotiation, respond timely to tenant requests for resolution including payment plans and resolving other issues raised.</li>
            <li>
              Attend mediation sessions as scheduled. A landlord may have an agent attend, but the landlord must be available by
              phone to participate if needed. The agent must have all necessary information and authority to resolve disputes and enter into agreements.
            </li>
            <li>During mediation, the landlord must make reasonable efforts to address all issues that could lead to eviction.</li>
            <!-- <li>
              If eligible for Targeted Financial Assistance, a landlord must apply for TFA within 5 days after being notified
              of EDP application approval. Landlords will be notified of eligibility and provided with instructions for the TFA application.
            </li> -->
          </ul>
          <br><br>For a tenant to meet the definition of good faith participation, the following conditions must be met:
          <br><ul>
            <li>Tenant must actively participate by attending mediation sessions as scheduled. If not, a tenant risks non-compliance and the legal process of eviction.</li>
            <li>Tenant must attempt to address all issues raised.</li>
            <li>Tenant must submit all requested materials for Targeted Financial Assistance within 5 days of notice.</li>
          </ul>
        </p>

        <!--<p class="faq-line-indent">
          As of January 1, 2022, Philadelphia Code 9-811 requires Landlords to participate in the
          Eviction Diversion Program in reasonable good faith prior to filing an eviction case in Court.
        </p> -->
        <!--<p class="faq-line-indent">
          As of April 1, 2021, the Philadelphia Municipal Court requires Landlords seeking to evict for nonpayment
          of rent to apply for Phase 4 rental assistance and participate in the Eviction Diversion
          Program first and wait 45 days before filing in court if the issues are not resolved.
        </p>-->
        <!--<p class="faq-line">
          What is Phase 4 rental assistance?
        </p>
        <p class="faq-line-indent">
          Phase 4 offers over $200 million dollars in federal rental and utility assistance to landlords and tenants
          in Philadelphia. To qualify, landlords must create a username and register the tenant-occupied
          property through <a href="https://phlrentassist.org/">https://phlrentassist.org/</a>.
        </p>
        <p class="faq-line-indent">
          To comply with the Municipal Court order when seeking to evict for non-payment of rent there are two
          options:
        </p>
        <ul class="faq-list">
          <b><li>Landlord Starts an Application</li></b>
          <p>
            A landlord must start an application, gather information and
            documents from their tenant to upload and submit a completed application. Just registering a
            property is not an application.
          </p>
          <b><li>Tenant Starts an Application</li></b>
          <p>
            If a tenant prefers not to give the info and documents to their
            landlord, the landlord may register the property and give the P4P# to the tenant. The tenant
            may use that P4P# in a tenant application and fill out all their information and upload
            documentation directly. Once the tenant submits their application, the landlord will receive an
            email prompting the landlord to sign off on the application.
          </p>
        </ul>
        <p class="faq-line-indent">
          When a landlord starts a rental assistance application and includes contact information for the tenant,
          that application (even if it is still in draft form) will be entered into the Eviction Diversion Program. The
          Eviction Diversion Program will reach out to the tenant to help the tenant complete the application
          along with the landlord and connect tenants with other needed resources including a housing
          counselor.
        </p>-->
        <!--
        <p class="faq-line">
          What is the Eviction Diversion Program?
        </p>
        <p class="faq-line-indent">
          The Eviction Diversion Program (EDP) is a free City service for residential tenants and landlords.
          The Program is an opportunity to work out an early resolution to prevent the costs and burden of a legal eviction. Applications
          are now open to landlords through <a href="https://eviction-diversion.phila.gov/#/">https://eviction-diversion.phila.gov/#/</a>
        </p>

        <p class="faq-line-indent">
          Applications are assigned appropriate pathways depending on the amount of back rent and fees that the landlord says is owed and additional factors.
          A tenant may be assigned a housing counselor, mediation session or additional resources like a webinar.  Tenants and landlords are
          encouraged to immediately try to work out agreements.
        </p>

        <p class="faq-line-indent">
          If assigned, a city-funded housing counselor will meet with the tenants before mediation to assess
          finances and find out if other issues need to be addressed. Housing counselors attend mediation
          sessions along with the tenant. Landlords are encouraged to communicate with housing counselors.
        </p>

        <p class="faq-line-indent">
          Mediation sessions are telephone conference calls scheduled in one-hour sessions. Landlords and
          tenants receive automated notice with the time and date of the mediation. For details visit
          <a href="https://eviction-diversion.phila.gov/#/FAQ">https://eviction-diversion.phila.gov/#/FAQ</a>.
          On the call, a trained mediator facilitates the conversation
          between landlord and tenant. The landlord says what the landlord is seeking. The tenant and the
          tenant’s housing counselor share the tenant’s goal and available resources.
        </p>

        <p class="faq-line-indent">
          If the landlord and tenant reach an agreement, the mediation coordinator writes down the agreement and provides a copy to both parties.
          Both parties review the agreement and follow the steps determined in mediation.
          These steps may include regular payments from the tenant and other issues the parties want to resolve such as repairs,
          utility bills and access to the property.
        </p>

        <p class="faq-line-indent">
          Landlords and tenant may reach agreements without the use of a housing counselor or mediation.
        </p>

        <p class="faq-line">
          What is good faith participation in Eviction Diversion?
        </p>
        <p class="faq-line-indent">
          Where landlords claim non-payment of rent, the goal is to facilitate rental assistance and repayment agreements and to avoid
          eviction whenever possible through mutually beneficial agreements. Where there are other issues, the goal is to facilitate
          an agreement that benefits both parties and prevents a disruptive eviction.
        </p>-->

        <!-- <p class="faq-line-indent">
          Mediation sessions will be scheduled if further support is needed in completing the application, if the
          application is denied, or if there are other eviction-related issues to resolve. Good faith participation
          means landlords must:
        </p>-->
        <!--
        <ul class="faq-list">
          <b><li>Provide Notice of Diversion Rights sent to tenant, updated ledger account of any balances owed and any
            additional issues to address through mediation as required.</li></b><br>
          <b><li>Provide current and accurate contact info for tenant as part of the Eviction Diversion Program application.</li></b><br>
          <b><li>Respond timely to requests for communication from a tenant, a housing counselor
            or City-funded agency to resolve issues with rental assistance or
            reach an agreement.</li></b><br>
          <b><li>Attend mediation sessions as scheduled. A landlord may have agent attend but the landlord
            must be available by phone to participate if needed. The agent must have all necessary
            information and authority to resolve disputes and enter into agreements.</li></b><br>
          <b><li>During mediation, the landlord must make reasonable efforts to address all lease issues that
            could lead to eviction in addition to applying for rental assistance.</li></b><br>
        </ul>
        <p class="faq-line-indent">
          Tenants must also actively participate. If not, a tenant risks non-compliance and the legal process of eviction.
        </p>-->

        <p class="faq-line">
          Helpful Links
        </p>
        <ul class="faq-list">
          <!--<li>
            <a
              href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/Bill+No.+21092001+As+Amended.pdf"
              target="_blank"
            >
              Philadelphia Code 9-811 “Eviction Diversion Program”</a>
          </li>-->
          <li>
            <a
              href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/NOTICE+OF+DIVERSION+RIGHTS+FINAL+3.10.2022.pdf"
              target="_blank"
            ><u>2022 Notice of Diversion Rights</u> </a>(most recent version)
          </li>
          <li>
            <a
              href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/Bill+220655+extending+EDP+through+June2024.pdf"
              target="_blank"
            ><u>Eviction Diversion Ordinance</u></a>
          </li>
          <!--<li>
            <a
              href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/Eviction+Diversion+Program+LL+Flyer+10.21.22.pdf"
              target="_blank"
            >Eviction Diversion Flyer for Landlords</a>
          </li>-->
          <li>
            <a
              href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/Template+Rent+Ledger.docx"
              target="_blank"
            ><u>Example Rent Ledger for Landlords to Upload</u></a>
          </li>
          <li>
            <a
              href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/2022+Agreement+Template.docx"
              target="_blank"
            ><u>Template Agreement for Landlord and Tenant</u></a>
          </li>
          <li>
            <a
              href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/Agreement+writing+tips+for+Landlords.pdf"
              target="_blank"
            ><u>Tips for Writing Agreements</u></a>
          </li>
          <li>
            <a
              href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/Tenant+Participation+Instructions+-+EDP+and+TFA.pdf"
              target="_blank"
            ><u>Tenant Participation Instructions</u></a>
          </li>
          <li>
            <a
              href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/LL+Application+Instructions+-+1.+How+to+Apply+for+EDP.pdf"
              target="_blank"
            ><u>How to Apply for EDP</u></a>
          </li>
          <!--<li>Rental Assistance Application & FAQ: <a href="https://www.phlrentassist.org">www.phlrentassist.org</a></li>-->
          <li>Questions about Rental Assistance: call <a href="tel:311">311</a></li>
          <li>For tenants who need to connect to the Eviction Diversion Program: call <a href="tel:215-523-9501">215-523-9501</a></li>
          <li>For landlords who need more information about applying for Eviction Diversion email <a href="mailto:evictiondiversionprogram@coraservices.org"><u>EvictionDiversionProgram@coraservices.org</u></a></li>
          <li>Eviction Diversion FAQ: <a href="https://eviction-diversion.phila.gov/#/FAQ"><u>https://eviction-diversion.phila.gov/#/FAQ</u></a></li>
          <li>For instructions on how to use Adobe Fill & Sign for electronic signature, please review these <a href="https://www.adobe.com/acrobat/how-to/fill-sign-pdf-forms-electronically.html"><u>instructions</u></a></li>
          <li>For instructions on how to apply to EDP, please review this <a href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/landlord+instruction+video.mp4"><u>video</u></a>.</li>
          <li>
            For instructions on how to apply to TFA, please review this <a href="https://www.canva.com/design/DAFZiHhBLW8/CIAZfpYtlTMni2i3wPHhFA/watch?utm_content=DAFZiHhBLW8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink"><u>video</u></a>. 
            If you already have a rental assist portal account, view this abbreviated <a href="https://www.canva.com/design/DAFZ0e6CEVw/SBOeiyv2OAXegjqziqmfLg/watch?utm_content=DAFZ0e6CEVw&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink"><u>video</u></a>.
          </li>
          <li>
            <a
              href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/LL+Application+Instructions+-+2.+How+to+Create+a+Portal+Account.pdf"
              target="_blank"
            ><u>How to Create a Portal Account for TFA</u></a>
          </li>
          <li>
            <a
              href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/LL+Application+Instructions+-+3.+How+to+Apply+for+TFA.pdf"
              target="_blank"
            ><u>How to Apply for TFA</u></a>
          </li>
          <li>
            <a
              href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/Tenant+Participation+Instructions+-+EDP+and+TFA.pdf"
              target="_blank"
            ><u>Tenant participation instructions</u></a>
          </li>
          <li>
            <a
              href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/Landlord+Affidavit_11.3.2023.pdf"
              target="_blank"
            ><u>Landlord Affidavit</u></a>
          </li>
          <li>
            <a
              href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/Tenant+Affidavit_11.3.2023.pdf"
              target="_blank"
            ><u>Tenant Affidavit</u></a>
          </li>
          <li>
            <a
              href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/Landlord+Affidavit_Spanish_11.3.2023.pdf"
              target="_blank"
            ><u>Landlord Affidavit (Spanish)</u></a>
          </li>
          <li>
            <a
              href="https://dpd-eviction-diversion-documents.s3.amazonaws.com/Tenant+Affidavit_Spanish_11.3.2023.pdf"
              target="_blank"
            ><u>Tenant Affidavit (Spanish)</u></a>
          </li>
        </ul><br>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>

<style>
.faq-line {
margin:0in;
font-weight: bold;
}
.faq-line-indent {
margin:0in;
margin-left:.5in;
margin-bottom: 5px;
}
.faq-list{
  margin-left:100px !important
}
.last{
  margin-bottom: 50px;
}
</style>
